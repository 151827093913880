export default function usePageMeta(route) {
  const pageMeta = {
    title: '',
    url: document.URL,
    meta: []
  }

  if (route.meta.title) {
    Object.assign(pageMeta, {
      title: route.meta.title
    })
  }

  if (route.meta.mtitle) {
    pageMeta.meta = pageMeta.meta.concat({
      name: 'title',
      content: String(route.meta.mtitle)
    })
  }

  if (route.meta.description) {
    pageMeta.meta = pageMeta.meta.concat({
      name: 'description',
      content: String(route.meta.description)
    })
  }

  if (route.meta.keywords) {
    pageMeta.meta = pageMeta.meta.concat({
      name: 'keywords',
      content: String(route.meta.keywords)
    })
  }

  return { pageMeta }
}